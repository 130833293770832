import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { io } from 'socket.io-client';
import './AppNewEncounter.scss';
import SpeakIcon from '../../assets/icons/speak-talk-voice-icon-white.svg';

const AppNewEncounter = (props) => {
  const { token, email } = props;

  const [encounter, setEncounter] = useState(`${email}-${Date.now()}`); // date string for now along with email
  const [transcribedText, setTranscribedText] = useState('transcribed text will show here');
  const [generatingChart, setGeneratingChart] = useState(false);
  const [chart, setChart] = useState(''); // openai output

  const recordingRef = useRef(false);
  const transcribedTextRef = useRef('transcribed text will show here');
  const socketRef = useRef(null);

  const navigate = useNavigate();

  // https://stackoverflow.com/questions/48327110/stream-live-audio-to-node-js-server
  let stream;
  let input;
  let processor;
  let audioContext;

  function closeAll() {
    const tracks = stream ? stream.getTracks() : null;
    const track = tracks ? tracks[0] : null;

    if (track) {
      track.stop();
    }

    if (processor) {
      if (input) {
        try {
          input.disconnect(processor);
        } catch (error) {
          console.warn('Attempt to disconnect input failed.');
        }
      }
      processor.disconnect(audioContext.destination);
    }

    if (audioContext) {
      audioContext.close().then(() => {
        input = null;
        processor = null;
        audioContext = null;
      });
    }
  }

  const stopRecording = () => {
    closeAll();

    socketRef.current.emit('stop_recording', {
      email,
      token
    });

    recordingRef.current = false;
    setGeneratingChart(true);

    setEncounter(`${email}-${Date.now()}`);
  }

  const startRecording = () => {
    recordingRef.current = true;

    if (!encounter) {
      return;
    }

    var bufferSize = 1024 * 16;
    audioContext = new AudioContext();
    // createScriptProcessor is deprecated. Let me know if anyone find alternative
    processor = audioContext.createScriptProcessor(bufferSize, 1, 1);
    processor.connect(audioContext.destination);

    navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then(handleMicStream).catch(err => {
      console.log('error from getUserMedia', err);
    });

    function handleMicStream(streamObj) {
      // keep the context in a global variable
      stream = streamObj;

      input = audioContext.createMediaStreamSource(stream);

      input.connect(processor);

      processor.onaudioprocess = e => {
        microphoneProcess(e); // receives data from microphone
      };
    }

    function microphoneProcess(e) {
      const left = e.inputBuffer.getChannelData(0); // get only one audio channel
      const left16 = convertFloat32ToInt16(left); // skip if you don't need this

      if (recordingRef.current) {
        socketRef.current.emit('audio', {
          email,
          token,
          audio: left16,
          encounter
        }); // send to server via web socket
      }
    }

    // Converts data to BINARY16
    function convertFloat32ToInt16(buffer) {
      let l = buffer.length;
      const buf = new Int16Array(l / 3);

      while (l--) {
        if (l % 3 === 0) {
          buf[l / 3] = buffer[l] * 0xFFFF;
        }
      }

      return buf.buffer;
    }
  }

  const connectSocket = () => {
    socketRef.current = io(process.env.REACT_APP_WS_URL, {
      path: "/ws/",
    });
  }

  const scrollDisplay = () => {
    const dispTarget = document.getElementById('text-display');
      
    if (dispTarget) {
      dispTarget.scrollTop = dispTarget.scrollHeight;
    }
  }

  useEffect(() => {
    connectSocket();
  
    socketRef.current.emit('start', {
      email,
      token,
      encounter
    });

    socketRef.current.on('err', data => {
      console.log('socket err', data);
    });

    socketRef.current.on('stt', data => {
      if (data?.partialText) {
        if (transcribedTextRef.current === 'transcribed text will show here') {
          transcribedTextRef.current = data.partialText;
        } else {
          transcribedTextRef.current = transcribedTextRef.current + ' ' + data.partialText;
        }

        setTranscribedText(transcribedTextRef.current);
        scrollDisplay();
      }

      if (data?.fullTranscribedText) {
        transcribedTextRef.current = data.fullTranscribedText;
        setTranscribedText(data.fullTranscribedText);
      }
    });

    socketRef.current.on('chart', data => {
      if (data?.chart) {
        setChart(data.chart);
      } else {
        setChart('Failed to genenerate chart');
      }
    });

    socketRef.current.on('disconnect', () => {
      setTimeout(() => {
        connectSocket();
      }, 1000);
    });

    startRecording();
  }, []);

  const getTextAreaValue = (transcribedText, chart) => {
    if (chart) return chart;

    return transcribedText;
  }


  // https://stackoverflow.com/a/30810322/2710227
  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }

    navigator.clipboard.writeText(text).then(function() { console.log('copied'); }, function(err) {
      console.error('Failed to copy', err);
    });
  }

  return (
    <div className="App__NewEncounter">
      <div className="App__NewEncounter-speak-group">
        <h1>{ chart ? 'Patient chart' : 'Start speaking' }</h1>
        {(!chart && !generatingChart) && <img
          className={`App__NewEncounter-speak-icon ${(recordingRef.current || !generatingChart) ? 'pulse' : ''}`}
          src={SpeakIcon}
          alt="person talking"
        />}
      </div>
      <textarea
        id="text-display" // can use ref
        className="App__NewEncounter-transcribed-text"
        value={getTextAreaValue(transcribedTextRef.current, chart)}
      />
      {!chart &&
        <button
          type="button"
          onClick={() => stopRecording()}
          disabled={!recordingRef.current || generatingChart}
        >
          { generatingChart ? 'Generating chart' : 'Done' }
        </button>
      }
      {chart && <button type="button" onClick={() => {
        copyTextToClipboard(chart);
      }}>
        Copy chart
      </button>}
    </div>
  );
}

export default AppNewEncounter;