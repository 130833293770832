import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logo from '../../assets/images/logo-no-bg.png';
import './Login.scss';

const Login = (props) => {
  const { apiUrl, token, setToken, setEmail } = props;

  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [formVals, setFormVals] = useState({
    email: '',
    password: ''
  });

  const updateVals = (which, val) => {
    setFormVals(prevState => ({
      ...prevState,
      [which]: val
    }));
  }

  const login = () => {
    if (!formVals.email || !formVals.password) {
      alert('Please make sure both email and password are filled in.');
      return;
    }

    // https://stackoverflow.com/a/9204568
    const validateEmail = (email) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    if (!validateEmail(formVals.email)) {
      alert('A valid email is required');
      return;
    }

    setProcessing(true);

    axios.post(
      `${apiUrl}/login`,
      {
        email: formVals.email,
        password: formVals.password
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data?.token) {
          setEmail(formVals.email);
          setToken(res.data.token);
        }
      } else {
        alert('Failed to login');
      }
    })
    .catch((err) => {
      alert('Failed to login');
      console.error(err);
    })
    .finally(() => {
      setProcessing(false);
    });
  }

  useEffect(() => {
    if (token) {
      navigate('/app/home');
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      navigate('/app/home');
    }
  }, []);

  return (
    <div className="Page__Login">
      <img className="Page__Login-logo" src={Logo} alt="alphie-ai logo"/>
      <h1>Alphie AI</h1>
      <p>Ambient Medical Scribe</p>
      <input
        type="email"
        placeholder="email"
        value={formVals.email}
        onChange={
          (e) => updateVals('email', e.target.value)
        }
      />
      <input
        type="password"
        placeholder="password"
        value={formVals.password}
        onChange={
          (e) => updateVals('password', e.target.value)
        }
      />
      <button
        type="button"
        className="Page__Login-btn"
        onClick={() => login()}
        disabled={processing}
      >
        {processing ? 'Logging in' : 'Login'}
      </button>
    </div>
  );
}

export default Login;