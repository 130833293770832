import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Prompts.scss';

const Prompts = (props) => {
  const { apiUrl, token, email } = props;

  const [processing, setProcessing] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [prevPromptLoaded, setPrevPromptLoaded] = useState(false);

  const savePrompt = () => {
    setProcessing(true);

    axios.post(
      `${apiUrl}/update-prompt`,
        {
          user: email,
          prompt,
          token
        }
      )
      .then((res) => {
        console.log('save', res);
        if (res.status === 201) {
          alert('Prompt saved!');
        } else {
          alert('Failed to save prompt');
        }
      })
      .catch((err) => {
        alert('Failed to save prompt');
        console.error(err);
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  const getPrevPrompt = () => {
    axios.post(
      `${apiUrl}/get-latest-prompt`,
        {
          user: email,
          token
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length) {
            if (res.data[0]?.prompt) {
              setPrompt(res.data[0].prompt);
            } else {
              alert('Failed to get latest prompt 2');
            }
          } else {
            setPrompt('');
          }
        } else {
          alert('Failed to get latest prompt');
        }
      })
      .catch((err) => {
        alert('Failed to get latest prompt');
        console.error(err);
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  useEffect(() => {
    if (!prevPromptLoaded) {
      setPrevPromptLoaded(true);
    }
  }, [prompt])

  useEffect(() => {
    getPrevPrompt();
  }, []);

  return (
    <div className="Page__prompts">
      {!prevPromptLoaded && <div className="Page__prompts-info">Getting previous prompt...</div>}
      {prevPromptLoaded && <textarea placeholder="enter your prompt" className="Page__prompt-form" value={prompt} onChange={(e) => setPrompt(e.target.value)}/>}
      {prevPromptLoaded && <button type="button" onClick={() => savePrompt()} disabled={processing}>Save Prompt</button>}
    </div>
  );
}

export default Prompts;