import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './AppHome.scss';

const AppHome = (props) => {
  const { token } = props;

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, []);

  // the previous encounters is disabled until can safely store audio files in encrypted object storage

  return (
    <div className="App__Home">
      <h1>Alphie AI</h1>
      {/* <Link to="/app/previous-encounters">Previous Encounters</Link> */}
      <Link to="/app/new-encounter">New Encounter</Link>
      <Link to="/app/prompts">Update Prompt</Link>
    </div>
  );
}

export default AppHome;